import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  Voucher: { 
    BookingReference: 0,
    CheckInDate: "",
    CheckOutDate: "",
    Supplier: "",
    SupplierId: 0,
    VoucherNumber: "",
    ItemId: 0,
    InvoiceExists: "",
    ContractCurrencyId: null,
    VatRegistered: false
  },
};

// Create slice
const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    setVoucher: (state, action) => {
        state.Voucher = action.payload;
    },

    updateVoucherItem: (state, action) => {
      state.Voucher[action.payload.name] = action.payload.value;
    },

    clearVoucher: (state) => {
      state.Voucher = initialState.Voucher;
    },
  },
});

export const { setVoucher, updateVoucherItem, clearVoucher } = voucherSlice.actions;

export default voucherSlice.reducer;
